export class DateUtils {
  static formatDate(date: Date): string {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return `${ monthNames[monthIndex] } ${ day }, ${ year }`;
  }

  static fromDDMMYYYYtoYYYYMMDD(date: string): string {
    if (!date) {
      return "";
    }
    const [day, month, year] = date.split("-");
    return `${ year }-${ month }-${ day }`;
  }
}
