<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    app
    stateless
    temporary
    right
    width="1100px"
    class="right-side-panel"
    ref="drawer">
    <PanelHeader :show.sync="show">
      Spend limit report
    </PanelHeader>
    <div class="d-flex justify-between ml-1 mt-8">
      <MyDateRangePicker :dateRange="range"
                         @setDateRange="setDateSearchRange"
                         class="mr-3"/>
      <div style="width: 200px;">
        <CommonSelect v-model="selectedUser"
                      :items="usersWithAllOption"
                      item-text="name"
                      return-object
                      placeholder="Select user"
                      height="30"/>
      </div>
      <v-spacer/>
      <v-btn class="ml-3" color="primary" icon @click="downloadCsv">
        <DownloadCsv/>
      </v-btn>
    </div>
    <SpendLimitReportTable :items="spendLimitItems" :loading="loading" :total-elements="totalElements"
                           @fetch-data="handleFetchData"/>
  </v-navigation-drawer>
</template>

<script>

  import panelVModel from "@/mixins/panelVModel"
  import PanelHeader from "@/components/PanelHeader.vue"
  import SpendLimitReportTable
    from "@/components/myCGCredits/budgetEntities/spend-limit-report/SpendLimitReportTable.vue"
  import {BudgetEntityService} from "@/services/budget-entity-service"
  import {Page} from "@/services/model/page"
  import DownloadCsv from "@/components/icons/DownloadCsv.vue"
  import MyDateRangePicker from "@/components/common/MyDateRangePicker.vue"
  import CommonSelect from "@/components/common/Select.vue"
  import {DateUtils} from "@/utils/date-utils"

  export default {
    name: "SpendLimitReportPanel",
    mixins: [panelVModel],
    components: { CommonSelect, MyDateRangePicker, DownloadCsv, SpendLimitReportTable, PanelHeader },
    props: {
      budgetEntity: {
        type: Object,
        required: false,
        default: () => ({})
      },
      users: {
        type: Array,
        required: false,
        default: () => []
      }
    },
    data: () => ({
      spendLimitItems: [],
      totalElements: 0,
      selectedUser: null,
      dateRange: {
        start: undefined,
        end: undefined
      },
      range: undefined,
      loading: false
    }),
    async mounted() {
      await this.fetchSpendLimitReport()
    },
    computed: {
      usersWithAllOption() {
        return [{ id: undefined, name: "All" }, ...this.users]
      }
    },
    methods: {
      async fetchSpendLimitReport(page = new Page({ page: 1, per_page: 5 })) {
        this.loading = true
        const spendLimitReportRequest = {
          budgetEntityId: this.budgetEntity?.id,
          userId: this.selectedUser?.id,
          startDate: DateUtils.fromDDMMYYYYtoYYYYMMDD(this.range?.start),
          endDate: DateUtils.fromDDMMYYYYtoYYYYMMDD(this.range?.end)
        }
        const response = await BudgetEntityService.fetchSpendLimitReport(spendLimitReportRequest, page)
        this.spendLimitItems = response.data
        this.totalElements = response.total
        this.loading = false
      },
      setDateSearchRange(range) {
        this.range = range
      },
      async downloadCsv() {
        const spendLimitReportRequest = {
          budgetEntityId: this.budgetEntity?.id,
          userId: this.selectedUser?.id,
          startDate: DateUtils.fromDDMMYYYYtoYYYYMMDD(this.range?.start),
          endDate: DateUtils.fromDDMMYYYYtoYYYYMMDD(this.range?.end)
        }
        const page = new Page({ page: 1, per_page: 1000000 })
        const csvData = await BudgetEntityService.fetchSpendLimitReport(spendLimitReportRequest, page, true)

        const blob = new Blob([csvData], { type: "text/csv" })
        const url = URL.createObjectURL(blob)

        const a = document.createElement("a")
        const date = new Date()
        const formattedDate = `${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}-${date.getFullYear()}`
        a.href = url
        a.download = `spend-limit-report-${formattedDate}.csv`
        document.body.appendChild(a)
        a.click()

        document.body.removeChild(a)
        URL.revokeObjectURL(url)
      },
      handleFetchData(options) {
        this.fetchSpendLimitReport(new Page({ page: options.page, per_page: options.itemsPerPage }))
      }
    },
    watch: {
      selectedUser: {
        handler: function (newVal, oldVal) {
          this.fetchSpendLimitReport()
        },
        deep: true
      },
      range: {
        handler: function (newVal, oldVal) {
          this.fetchSpendLimitReport()
        },
        deep: true
      },
      budgetEntity: {
        handler: function (newVal, oldVal) {
          if (oldVal !== newVal) {
            this.spendLimitItems = []
          }
          this.fetchSpendLimitReport()
        },
        deep: true
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '@/css/common/right-side-panel.scss';

  .right-side-panel {
    &::v-deep > .v-navigation-drawer__content {
      gap: 20px;
    }

    &__content-wrapper {
      gap: 32px;
    }
  }

</style>
